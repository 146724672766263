import * as types from "../types";

const initialState = {
  hasError: false,
  isSample: false,
  isLoading: true,
  isLoadingContent: true,
  errorMessage: null,
  newPermission: null,
  mechanicalChecked: false,

  progress: {
    loaded: 0,
    total: 0,
  },

  type: null,
  activeTab: null,
  activeSection: "section_1",
  activePantograph: 1,
  selectedSection: 1,
  isCurrentSpanData: false,

  contentLoaded: {
    staticModeling: false,
    staticResult: false,
    dynamicModeling: false,
    dynamicResult: false,
  },

  current_time: 0,
  duration: 0,

  trainVelocity: 120,
  dropperElasticity: false,
  movieMaker: false,
  displyedDraggableNotic: false,
  displyedSectionsDraggableNotic: false,

  info: {
    id: null,
    name: "",
    token: "",
    model: "",
    tags: [],
    permission: "",
    edited_times: [],
  },

  access: {
    full: [],
    write: [],
    read: [],
  },

  modals: {
    contactWire: false,
    dropper: false,
    messengerWire: false,
    messengerWireSupport: false,
    steadyArm: false,
    stitchWire: false,
  },

  staticRequest: {},
  dynamicRequest: {},

  sections: [],
  pantographs: [],
  notifications: [],

  analysisSection: {
    startSection: 1,
    startSpan: 1,
    endSection: 1,
    endSpan: 2,
  },
};

export default function catenaryReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_CATENARY_REQUEST:
      return {
        ...state,
        isLoading: !state.isLoading ? false : true,
        isLoadingContent: true,
        progress: { loaded: 0, total: 0 },
      };
    case types.GET_CATENARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingContent: false,
        ...payload,
      };
    case types.GET_CATENARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoadingContent: false,
        hasError: true,
        errorMessage: payload,
      };
    case types.RESET_CATENARY_ERROR:
      return { ...state, hasError: false, errorMessage: null };
    case types.SET_LOADING_CATENARY:
      return { ...state, isLoadingContent: payload };

    case types.CHANGE_CATENARY_NAME:
      return { ...state, info: { ...state.info, name: payload } };
    case types.CHANGE_CATENARY_ACTIVE_TAB:
      return { ...state, activeTab: payload };
    case types.CHANGE_CATENARY_ACTIVE_SECTION:
      return { ...state, activeSection: payload };

    case types.SET_CONTENT_LOADED:
      return { ...state, contentLoaded: payload };

    case types.ADD_CATENARY_SPAN:
    case types.DELETE_CATENARY_SPAN:
    case types.SET_CATENARY_SECTIONS:
    case types.CHANGE_CATENARY_ACTIVE_SPAN:
      return { ...state, sections: payload };

    case types.ADD_ACCOUNT_TO_CATENARY:
      return { ...state, access: payload };

    case types.ADD_CATENARY_SECTION:
      return {
        ...state,
        sections: [...state.sections, payload],
        activeSection: payload.id,
      };
    case types.ADD_CATENARY_NOTIFICATION:
      return { ...state, notifications: [...state.notifications, payload] };

    case types.CHECK_MECHANICAL_PROPERTIES:
      return { ...state, mechanicalChecked: payload };
    case types.DELETE_CATENARY_SECTION:
      return { ...state, ...payload };

    case types.CANCEL_REQUEST:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          status: "cancelled",
        },
      };

    case types.UPDATE_CATENARY_STATIC_STATUS:
      return {
        ...state,
        staticRequest: { ...state.staticRequest, status: payload },
      };
    case types.UPDATE_CATENARY_DYNAMIC_STATUS:
      return {
        ...state,
        dynamicRequest: { ...state.dynamicRequest, status: payload },
      };

    case types.SET_IS_CURRENT_SPAN_DATA:
      return { ...state, isCurrentSpanData: payload };

    case types.SET_OPEN_CATENARY_MODAL:
      return {
        ...state,
        modals: { ...state.modals, [payload.key]: payload.value },
      };
    case types.DISPLAYED_DRAGGABLE_NOTIC:
      return { ...state, displyedDraggableNotic: payload };
    case types.DISPLAYED_SECTIONS_DRAGGABLE_NOTIC:
      return { ...state, displyedSectionsDraggableNotic: payload };

    case types.CHANGE_SELECTED_SECTION:
      return { ...state, selectedSection: payload };
    case types.RESET_CATENARY_STATE:
      return initialState;

    case types.SET_CATENARY_PANTOGRAPHS:
      return { ...state, pantographs: payload };
    case types.SET_ACTIVE_PANTOGRAPH:
      return { ...state, activePantograph: payload };
    case types.ADD_PANTOGRAPH_TO_CATENARY:
      return {
        ...state,
        activePantograph: payload.id,
        pantographs: [...state.pantographs, payload],
      };

    case types.SET_CATENARY_ACCESS:
      return { ...state, access: payload };

    case types.SET_CATENARY_TRAIN_VELOCITY:
      return { ...state, trainVelocity: payload };
    case types.SET_CATENARY_DROPPER_ELASTICITY:
      return { ...state, dropperElasticity: payload };
    case types.SET_CATENARY_MOVIE_MAKER:
      return { ...state, movieMaker: payload };

    case types.SET_CATENARY_CURRENT_TIME:
      return { ...state, current_time: payload };

    case types.SET_CATENARY_ANALYSIS_SECTION:
      return { ...state, analysisSection: payload };

    case types.SET_LOADING_PROGRESS:
      return {
        ...state,
        progress: { loaded: payload.loaded, total: payload.total },
      };

    default:
      return state;
  }
}
